var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": "Entities"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('router-link', {
          staticClass: "btn btn-primary mb-3",
          attrs: {
            "data-cy": "new-entity",
            "to": {
              name: 'entity-wizard'
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus fa-fw"
        }), _vm._v(" New Entity")])];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "content"
  }, [_c('DataTable', {
    key: _vm.dataTableKey,
    attrs: {
      "items": _vm.entities,
      "total": _vm.totalEntities,
      "loading": _vm.loadingAction.list,
      "columns": _vm.columns,
      "actions": _vm.actions,
      "fetch-items": _vm.listEntities,
      "default-sort-key": "legalName",
      "default-sort-direction": 1
    },
    scopedSlots: _vm._u([{
      key: "legalName",
      fn: function (slotProps) {
        return [_c('router-link', {
          staticClass: "nav-link",
          attrs: {
            "to": {
              name: 'entity-view',
              params: {
                id: slotProps.data._id
              }
            }
          }
        }, [_vm._v(_vm._s(slotProps.data.legalName))])];
      }
    }, {
      key: "parentEntityId",
      fn: function (slotProps) {
        return [_vm._v(" " + _vm._s(_vm.getParentEntity(slotProps.data.parentEntityId)) + " ")];
      }
    }, {
      key: "ultimateParentEntityId",
      fn: function (slotProps) {
        return [_vm._v(" " + _vm._s(_vm.getParentEntity(slotProps.data.ultimateParentEntityId)) + " ")];
      }
    }])
  })], 1), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.remove,
      "title": "Delete Entity",
      "text": `Please confirm you would like to remove entity:<br/><br/><strong>${_vm.modals.remove.legalName}</strong>`
    },
    on: {
      "close": function () {
        _vm.modals.remove = false;
      },
      "submit": _vm.onRemoveEntity
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }